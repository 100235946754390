/* ==========================================================================
   LayoutLogitravelHome > Client
   ========================================================================== */

/* Modules */
require('intersection-observer');

const { elementExist } = require('@bluekiri/alexandria/helpers/domHelpers');

const { resolveComponents, getCookies, getTopButton, getHeader } = require('./components');

(function LayoutLogitravelHome(selector = '[data-component="LayoutLogitravelHome"]') {
    if (elementExist(selector)) {
        const element = document.querySelector(selector);

        const config = {
            ...(function getConfig() {
                if (!element.dataset.config) return '';

                const result = JSON.parse(element.dataset.config);
                element.dataset.config = '';
                return result;
            })()
        };

        /**
         * Setting public path to the webpack exposed variable
         */
        /* eslint-disable */
        __webpack_public_path__ = config.publicPath;
        /* eslint-enable */

        const components = resolveComponents()
            .filter(component => component.name !== 'Header')
            .map(component => ({
                loader: component.loader,
                element: document.querySelector(`[data-component="${component.name}"]`),
                name: component.name
            }))
            .filter(component => component.element);

        /**
         * Observer to lazy load modules based on
         * currently viewed components in the viewport
         */
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        const { loader, name } = components
                            .filter(component => component.element === entry.target)
                            .reduce(acc => acc);

                        /**
                         * Init component with its loader and then
                         * unobserve from this entry so we dont
                         * call the init of the component more
                         * than once
                         */
                        loader().then(module => {
                            observer.unobserve(entry.target);

                            const mod = module.default;

                            if (!(typeof mod === 'function')) {
                                return;
                            }

                            if (name === 'HeaderCarouselRxjs') {
                                mod(`[data-component='HeaderCarouselRxjs']`, {
                                    swiper: {
                                        // slidesPerView: 1,
                                        // centeredSlides: true,
                                        // watchSlidesVisibility: true,
                                        // spaceBetween: 0,
                                        // speed: 500,
                                        // effect: 'fade',
                                        // fadeEffect: {
                                        //     crossFade: true
                                        // },
                                        // loop: false,
                                        // preventInteractionOnTransition: true,
                                        // autoplay: {
                                        //     delay: 5000,
                                        //     disableOnInteraction: false
                                        // }
                                        slidesPerView: 1,
                                        centeredSlides: true,
                                        watchSlidesVisibility: true,
                                        watchSlidesProgress: true,
                                        spaceBetween: 0,
                                        speed: 200,
                                        effect: 'fade',
                                        fadeEffect: {
                                            crossFade: true
                                        },
                                        loop: false,
                                        preventInteractionOnTransition: true,
                                        autoplay: {
                                            delay: 7000,
                                            disableOnInteraction: false
                                        }
                                    }
                                });

                                return;
                            }

                            /* Execute module */
                            mod();
                        });
                    }
                });
            },
            {
                root: null, // avoiding 'root' or setting it to 'null' sets it to default value: viewport
                rootMargin: '0px',
                threshold: 0.15
            }
        );

        components.forEach(component => {
            observer.observe(component.element);
        });

        /* Cookies */
        getCookies().then(module => module.default());

        /* TopButton */
        getTopButton().then(module => module.default());

        /* Header Logitravel INIT */
        getHeader().then(module => module.default);
    }
})();
