/* ==========================================================================
   MLayoutLogitravelHome > Client > Components
   ========================================================================== */

/**
 * HeaderCarousel
 */
const getHeaderCarousel = () => {
    return import(
        /* webpackChunkName: "header-carousel" */
        '@bluekiri/alexandria-contrib-components/components/header-carousel/client/js'
    );
};

/**
 * HeaderCarouselRxjs
 */
const getHeaderCarouselRxjs = () => {
    return import(
        /* webpackChunkName: "header-carousel-rxjs" */
        '@bluekiri/alexandria-contrib-components/components/header-carousel-rxjs/client/js'
    );
};

/**
 * Header
 */
const getHeader = () => {
    return import(
        /* webpackChunkName: "header" */
        '@bluekiri/alexandria-logitravel/components/header/client/js'
    );
};

/**
 * Footer
 */
const getFooter = () => {
    return import(
        /* webpackChunkName: "footer" */
        '@bluekiri/alexandria-logitravel/components/footer/client/js'
    );
};

/**
 * Cookies
 */
const getCookies = () => {
    return import(
        /* webpackChunkName: "cookies" */
        '@bluekiri/alexandria-contrib-components/components/cookies/client/js'
    );
};

/**
 * TopButton
 */
const getTopButton = () => {
    return import(
        /* webpackChunkName: "cookies" */
        '@bluekiri/alexandria-contrib-components/components/top-button/client/js'
    );
};

/**
 * Searcher
 */
const getSearcher = () => {
    return import(
        /* webpackChunkName: "multisearcher" */
        '@logitravel/alex-comp-mar/components/multisearcher/client/js'
    );
};

/**
 * ListCardSimpleHover
 */
const getListCardSimpleHover = () => {
    return import(
        /* webpackChunkName: "list-card-simple-hover" */
        '@logitravel/alex-comp-mar/components/list-card-simple-hover/client/js'
    );
};

/**
 * Campaign
 */
const getCampaigns = () => {
    return import(
        /* webpackChunkName: "campaigns" */
        '@logitravel/alex-comp-mar/components/tabs-banner-product-card-carousel/client/js'
    );
};

/**
 * MapCoasts
 */
const getMapCoasts = () => {
    return import(
        /* webpackChunkName: "map-coasts" */
        '@logitravel/alex-comp-mar/components/map-coasts/client/js'
    );
};

/**
 * LastNews
 */
const getLastNews = () => {
    return import(
        /* webpackChunkName: "last-news" */
        '@logitravel/alex-comp-mar/components/last-news/client/js'
    );
};

/**
 * ProductsBanner
 */
const getProductsBanner = () => {
    return import(
        /* webpackChunkName: "products-banner" */
        '@logitravel/alex-comp-mar/components/products-banner/client/js'
    );
};

/**
 * BannerButtonWidth
 */
const getBannerButtonWidth = () => {
    return import(
        /* webpackChunkName: "banner-button-width" */
        '@logitravel/alex-comp-mar/components/banner-button-width/client/js'
    );
};

/**
 * DealsCarousel
 */
const getDeals = () => {
    return import(
        /* webpackChunkName: "deals" */
        '@logitravel/alex-comp-mar/components/deals-carousel/client/js'
    );
};

/**
 * Comarketing
 */
const getComarketing = () => {
    return import(
        /* webpackChunkName: "deals-carousel" */
        '@logitravel/alex-comp-mar/components/comarketing/client/js'
    );
};

/**
 * Destinations
 */
const getDestinations = () => {
    return import(
        /* webpackChunkName: "masonry-carousel" */
        '@bluekiri/alexandria-contrib-components/components/masonry-carousel/client/js'
    );
};

/**
 * BlogFeed
 */
const getBlog = () => {
    return import(
        /* webpackChunkName: "blog" */
        '@logitravel/alex-comp-mar/components/blog-feed/client/js'
    );
};

/**
 * NewsletterSubscription
 */
const getNewsletter = () => {
    return import(
        /* webpackChunkName: "newsletter" */
        '@logitravel/alex-comp-mar/components/newsletter-subscription/client/js'
    );
};

/**
 * BannerRestrictionsAub
 */
const getBannerRestrictionsAub = () => {
    return import(
        /* webpackChunkName: "banner-restrictions-aub" */
        '@logitravel/alex-aubergine/components/banner-restrictions-aub/client/js'
    );
};

/**
 * CollapseLinksProducts
 */
const getCollapseLinksProducts = () => {
    return import(
        /* webpackChunkName: "collapse-links-products" */
        '@logitravel/alex-comp-mar/components/collapse-links-products/client/js'
    );
};

/**
 * TrustedShops
 */
const getTrustedShops = () => {
    return import(
        /* webpackChunkName: "trusted-shops" */
        '@bluekiri/alexandria-contrib-components/components/trusted-shops/client/js'
    );
};

/**
 * Components
 */
const COMPONENTS = [
    {
        loader: getHeader,
        name: 'Header'
    },
    {
        loader: getFooter,
        name: 'Footer'
    },
    {
        loader: getHeaderCarousel,
        name: 'HeaderCarousel'
    },
    {
        loader: getHeaderCarouselRxjs,
        name: 'HeaderCarouselRxjs'
    },
    {
        loader: getSearcher,
        name: 'Multisearcher'
    },
    {
        loader: getListCardSimpleHover,
        name: 'ListCardSimpleHover'
    },
    {
        loader: getMapCoasts,
        name: 'MapCoasts'
    },
    {
        loader: getBannerButtonWidth,
        name: 'BannerButtonWidth'
    },
    {
        loader: getCampaigns,
        name: 'TabsBannerProductCardCarousel'
    },
    {
        loader: getDeals,
        name: 'DealsCarousel'
    },
    {
        loader: getComarketing,
        name: 'Comarketing'
    },
    {
        loader: getDestinations,
        name: 'MasonryCarousel'
    },
    {
        loader: getCollapseLinksProducts,
        name: 'CollapseLinksProducts'
    },
    {
        loader: getNewsletter,
        name: 'NewsletterSubscription'
    },
    {
        loader: getBlog,
        name: 'BlogFeed'
    },
    {
        loader: getTrustedShops,
        name: 'TrustedShops'
    },
    {
        loader: getLastNews,
        name: 'LastNews'
    },
    {
        loader: getProductsBanner,
        name: 'ProductsBanner'
    },
    {
        loader: getBannerRestrictionsAub,
        name: 'BannerRestrictionsAub'
    }
];

/**
 * Resolver components
 */
function resolveComponents() {
    return COMPONENTS;
}

/* Exports */
module.exports = {
    getCookies,
    getTopButton,
    getHeader,
    resolveComponents
};
